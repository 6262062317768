@import url('https://fonts.googleapis.com/css2?family=Inter&family=Manrope:wght@400;500;600&display=swap');

.rotation {
    animation: rotating 2s linear infinite;
}

@keyframes rotating {
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
}
